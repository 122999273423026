.app-admin-container {
  max-width: 100%;
  height: 100vh;
  .rightside-content-hold {
    position: relative;
    min-height: calc(100% - 99px);
    margin: 18px;
  }
  //.rightside-content-hold-full {
  //  height: calc(100% - 64px) !important;
  //}
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: $sidebar-width;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  -webkit-transition: all $sidebarTrnTime $transitionTiming;
  transition: all $sidebarTrnTime $transitionTiming;
  .navigation-hold {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 130px;
    left: 0;
    -webkit-transition: all $sidebarTrnTime $transitionTiming;
    transition: all $sidebarTrnTime $transitionTiming;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      padding: 5px;
    }
  }
}

#sidebar-right {
  top: 71px;
  right: 10px;
  position: fixed;
  height: calc(100vh - 81px);
  z-index: 998;
  width: 64px;
  background-color: #ffffff;
  -webkit-transition: all $sidebarTrnTime $transitionTiming;
  transition: all $sidebarTrnTime $transitionTiming;
  overflow: hidden;
}

.sidebar-right-content {
  width: 700px;
  height: 100%;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 64px;
  display: none;
}

.sidebar-right-open {
  width: 700px !important;
}

.sidebar-right-open .sidebar-right-content {
  display: block !important;
}

@media only screen and (max-width: 700px) {
  .sidebar-right-open {
    width: 100% !important;
  }
  .sidebar-right-content {
    width: 100% !important;
  }
}

#sidebar-auto-padding {
  width: 100%;
  height: 100%;
}

.sidebar-right-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-content-wrap {
  position: relative;
  float: right;
  height: 100vh;
  transition: width $sidebarTrnTime $transitionTiming;
  background: $main-content-wrap;
  padding: 0 2px;
  width: calc(100% - 64px);
  &.ps > .ps__rail-y {
    z-index: 9999;
  }
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all $sidebarTrnTime $transitionTiming;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

// --------
.app-admin-container.sidebar-full:not(.navigation-top) {
  .main-content-wrap {
    width: calc(100% - #{$sidebar-width});
  }
  .sidebar-panel {
    width: $sidebar-width;
    overflow: hidden;
  }
}


.app-admin-container.sidebar-compact {
  .main-content-wrap {
    width: calc(100% - #{$compact-sidebar-width});
  }
  .sidebar-panel {
    width: $compact-sidebar-width;
    //.branding {
    //  width: $compact-sidebar-width;
    //
    //  .app-logo {
    //    margin: auto;
    //  }
    //}
    .navigation-hold {
      overflow: visible !important;
    }
  }
}

.app-admin-container.sidebar-compact-big {
  .main-content-wrap {
    width: calc(100% - #{$compact-big-sidebar-width});
  }
  .sidebar-panel {
    width: $compact-big-sidebar-width;
    //.branding {
    //  width: $compact-big-sidebar-width;
    //  .app-logo {
    //    margin: auto;
    //  }
    //}
    .navigation-hold {
      overflow: visible !important;
    }
  }
}

//.app-admin-container.sidebar-closed {
//  .sidebar-panel {
//    overflow: hidden;
//    left: -$sidebar-width;
//    .branding {
//      left: -$sidebar-width;
//      width: 48px;
//      .app-logo-text {
//        // display: none;
//      }
//    }
//  }
//  .main-content-wrap {
//    width: 100%;
//  }
//  .topbar {
//    padding-left: 0;
//  }
//}

#sidenavToggle {
  margin: 0 4px;
}

.fixed-topbar {
  .rightside-content-hold {
    overflow: auto;
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    top: 64px;
  }
}


.layout-intransition {
  //.branding {
  //  display: none !important;
  //}

  .app-user-controls,
  .app-user-name {
    // visibility: hidden;
    opacity: 0 !important;
  }
}

.sidebar-compact,
.sidebar-compact-big {
  .app-user,
  .app-logo-text {
    display: none;
  }
  //.app-logo-small {
  //  display: block !important;
  //}
}



[dir=rtl] {
  &.app-admin-container.sidebar-closed {
    .sidebar-panel {
      right: -$sidebar-width
    }
    //.branding {
    //  left: auto !important;
    //  right: -$sidebar-width;
    //}
  }
  .main-content-wrap {
    float: left;
  }
  .sidebar-panel {
    right: 0;
    left: auto !important;
  }
}

.app-admin-container.sidebar-full.navigation-top {
  .sidebar-panel {
    left: 0;
  }
}
.app-admin-container.navigation-top {
  .main-content-wrap {
    float: none;
    height: calc(100vh - 48px);
  }
  .rightside-content-hold {
    max-width: 1200px;
    margin: 0 auto;
  }
  .sidebar-panel {
    overflow: hidden;
    left: -$sidebar-width;
  }
}

@media (max-width: 425px) {
  .app-admin-container.sidebar-full {
    .main-content-wrap {
      width: 100% !important;
    }
  }
}

.full-screen-dialog .mat-dialog-container {
  max-width: none;
  width: 80vw;
  height: 80vh;
}
