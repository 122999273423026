.reporting-top-wrapper {
  margin-top: 0;
}

.reporting-channel {
  float: left;
  margin-right: 12px;

  .mat-select {
    width: 180px;
    height: 54px;
    border: solid 1px #b9b9b9;
    background: #fff;
    border-radius: 4px;
  }

  .mat-select-value {
    padding: 16px 20px 16px 12px;
  }

  .mat-select-arrow-wrapper {
    padding-right: 15px;
  }

}

.jump-left {
  margin-left: -4px;
}

.reporting-date-range {
  float: left;
  margin-right: 12px;
}

.reporting-date {
  display: flex;
  align-items: center;
  float: left;
  height: 56px;
  background: #fff;
  border: solid 1px #b9b9b9;

  .mat-input-element {
    height: 22px;
    border: 0px;
    width: 110px;
    padding: 16px 12px 16px 12px;

  }
}

::ng-deep .channel-selector.mat-form-field {
  .mat-form-field-underline {
    background-color: transparent;
  }
}

.channel-selector {
  height: 54px;
  width: 160px;
  background: #fff;
  border: solid 1px #b9b9b9;
  margin-right: 5px;
  padding: 0px 10px;

  .mat-form-field-underline {
    display: none !important;
  }


  .mat-input-element {
    height: 22px;
    border: 0px;
    width: 86px;
    padding: 16px 12px 16px 12px;
    border-top: solid 1px #b9b9b9;
    border-bottom: solid 1px #b9b9b9;
  }
}

.reporting-cards mat-card.mat-card {
  margin: 24px 26px 0 0;
}

.reporting-line-chart mat-card.mat-card {
  margin: 24px 26px 0 0;
}

.reporting-cards .reporting-line-chart mat-card-title.mat-card-title {
  margin-bottom: 0px;
}


.reporting-cards mat-card small.text-muted {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
}


.reporting-cards h3 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: $font-weight-medium;
  font-display: swap;
  font-size: 20px !important;
}

.reporting-cards .reporting-line-chart div:last-child {
  mat-card.mat-card {
    margin: 24px 0 0 0;
  }
}

.mat-raised-button.box {
  width: 56px;
  height: 56px;
  min-width: 0px;
  min-height: 0px;
  box-shadow: none !important;
  float: left;
  border-radius: 4px;
}

.mat-raised-button.first {
  margin-right: 12px;
  margin-left: 8px;
}

.mat-card .mat-divider-horizontal {
  position: absolute;
  left: 0 !important;
  width: 100% !important;
}

