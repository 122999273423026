.powerbi-integration {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);

  .displaySnack {
    display: flex;
    gap: 6px;
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 6px;
    right: 4px;
    transition: opacity 2ms ease-out;
    border-radius: 4px;
    padding: 3px 8px;

    &.show {
      opacity: 1;
      transition: opacity 2ms ease-in;
      background: rgb(237, 247, 237);
      color: #2e7d32;
    }

    &.error {
      background: rgb(253, 237, 237);
      color: #d32f2f;
    }
  }

  :host {
    display: block;
    height: 100%;
    width: 100%;
  }

  iframe {
    height: calc(100vh - 100px);
    display: block;
    width: 100%;
    border: none;
  }
}
