/*---- Dialogs -----*/
.edit-text-chips,
.edit-bot-action {
  display: inline-block;
    width: 100%;
    /*margin-left: 20px;*/
}

.create-text-chips {
  display: block;
}

input.wc-shellinput {
  margin-left: 32px;
}
.edit-actions {
  display: inline-block;
  vertical-align: top;
}

.edit-form-field {
  margin-left: 20px;  
}
