/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}
.session-progress {
  position: relative;
  bottom: -5px;
  z-index: 9999;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.session-form-hold > .mat-card {
  margin: 0;
}
.session-lockscreen {
  max-width: 320px;
}
.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}
.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: .5rem;
}

.login-bg, .signup-bg {
  background: url(~assets/images/bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;

  // Logo Design for Sign In/Up page
  .mat-icon.logo{
    width: 150px;
    height: 80px;
  }

  .logo{
    margin-bottom: 20px !important;
  }

  .text-muted{
    font-size: 20px;
    font-weight: 600;
    color: #373737 !important;
    letter-spacing: 1px;
  }
}
