.breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    &:not(:first-child):before {
      content: "/\A0";
      padding: 0 8px;
    }
  }
}

.breadcrumb-bar {
  position: relative;
  width: 100%;
  .breadcrumb {
    padding: 0 .333rem;
    overflow: hidden;
    li {
      line-height: 40px;
      &:not(:first-child):before {
        padding: 0 8px;
      }
      a {
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }
}
