@import '@angular/material/theming';

//   @mixin multi-line-option-theme($theme) {
//       .multi-line-option {
//         .mat-option-text {
//             @include mat-line-wrapper-base();
//             padding: 0;
//         }
//       }
//   }
  
  @mixin multi-line-option-typography($config) {
    .multi-line-option {
      @include mat-line-base(mat-font-size($config, body-1));
      .mat-line {
        line-height: 1em;
      }
      &.mat-3-line {
        @include mat-line-base(mat-font-size($config, caption));
        font-size: mat-font-size($config, body-1);
      }
    }
  }
  
  $config: mat-typography-config();
  
  @include mat-core($config);
  @include multi-line-option-typography($config);
// @include mat-core();

// Keep atleast one file
@import "themes/ebo-theme";

$background: map-get($egret-theme, background);
$foreground: map-get($egret-theme, foreground);
