/*
* REQUIRED STYLES
*/
@import "main/scaffolding";
@import "main/typography";
@import "main/layout";
@import "main/header";
@import "main/sidenav";
@import "main/body";

/*
* ALTERNATIVE STYLES
*/
@import "main/breadcrumb"; // REQUIRED ONLY FOR BREADCRUMBS
@import "main/notifications"; // REQUIRED ONLY FOR NOTIFICATIONS

@import "main/sidebar"; // REQUIRED ONLY FOR SIDE NAVIGATION LAYOUT
@import "main/header-side"; // REQUIRED ONLY FOR SIDE NAVIGATION LAYOUT

@import "main/topnav"; // REQUIRED ONLY FOR TOP NAVIGATION LAYOUT
@import "main/header-top"; // REQUIRED ONLY FOR TOP NAVIGATION LAYOUT


