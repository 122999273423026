@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

// General
$transition-duration: 300ms !default;
$gutter: 1rem !default;
$transitionTiming: cubic-bezier(.35,0,.25,1);
$sidebarTrnTime: .3s;
$main-content-wrap: #F1F3F5;

// Widths
$sidebar-width: 15rem !default;
$compact-sidebar-width: 64px !default;
$compact-big-sidebar-width: 150px !default;

// Typography
$font-family-base: 'Roboto', sans-serif !default;
$font-size-base: .9375rem !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-margin-bottom: calc(1rem / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   400 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$main-title-color: #3F4A59;
$secondary-bg-color: #ecf0f1;
$main-standard-background: #8b84d7;
$secondary-standard-background: #95a5a6;
$bg-color: #52c7b8;
